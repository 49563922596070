import { CommonErrorJson } from '../../../common-error';

export interface APIResponse<T> {
  success: true;
  result: T;
}

export interface APIResponseFailure {
  success: false;
  error: CommonErrorJson | APIError;
}

export interface APIError {
  name: string;
  message: string;
}
