import { DateWrapped, DateWrapper, DateWrapperProperty } from '../types/date-wrapper';

const DATE_WRAPPER_PROPERTY: DateWrapperProperty = 'dæt';

function isDateWrapper(obj: any): obj is DateWrapper {
  return (
    obj != null &&
    Object.prototype.hasOwnProperty.call(obj, DATE_WRAPPER_PROPERTY) &&
    typeof obj[DATE_WRAPPER_PROPERTY] === 'string' &&
    Object.keys(obj).length === 1
  );
}

function wrapDateDeep<T>(target: T): DateWrapped<T> {
  if (target == null || typeof target !== 'object') {
    return target as DateWrapped<T>;
  }

  for (const key of Object.keys(target) as Array<keyof T>) {
    const val = target[key];
    if (typeof val === 'object') {
      if (val instanceof Date) {
        (target as any)[key] = { [DATE_WRAPPER_PROPERTY]: val.toISOString() };
      } else {
        (target as any)[key] = wrapDateDeep(val as any);
      }
    }
  }
  return target as DateWrapped<T>;
}

function unwrapDateDeep<T>(target: DateWrapped<T>): T {
  if (target == null || typeof target !== 'object') {
    return target as T;
  }

  for (const key of Object.keys(target) as Array<keyof DateWrapped<T>>) {
    const val = target[key];
    if (typeof val === 'object') {
      if (isDateWrapper(val)) {
        (target as any)[key] = new Date(val[DATE_WRAPPER_PROPERTY]);
      } else {
        (target as any)[key] = unwrapDateDeep(val as any);
      }
    }
  }
  return target as T;
}

export function wrapDate<T>(target: T): DateWrapped<T> {
  if (target == null || typeof target !== 'object') {
    return target as DateWrapped<T>;
  }
  return wrapDateDeep(target);
}

export function unwrapDate<T>(target: DateWrapped<T>): T {
  if (target == null || typeof target !== 'object') {
    return target as T;
  }
  return unwrapDateDeep<T>(target);
}
