export function hasKey<T>(obj: T, key: PropertyKey): key is keyof T {
  return obj != null && Object.prototype.hasOwnProperty.call(obj, key);
}

export function hasKeyExtend<T, U extends PropertyKey>(obj: T, key: U): obj is T & { [key in U]: unknown } {
  return obj != null && Object.prototype.hasOwnProperty.call(obj, key);
}

export function hasKeys<T>(obj: T, keys: Array<PropertyKey>): keys is Array<keyof T> {
  return keys.every((key) => obj != null && Object.prototype.hasOwnProperty.call(obj, key));
}

export function hasKeysExtend<T, U extends PropertyKey>(obj: T, keys: Array<U>): obj is T & { [key in U]: unknown } {
  return keys.every((key) => obj != null && Object.prototype.hasOwnProperty.call(obj, key));
}
