import { Storage } from '@ionic/storage';

export class NoopStorage extends Storage {
  constructor() {
    super();
  }
  override async create() {
    return this;
  }
  override async defineDriver() {}
  override get driver(): string | null {
    return 'noop';
  }
  override async get(key: string) {
    return null;
  }
  override async set(key: string, value: any) {}
  override async remove(key: string): Promise<any> {}
  override async clear(): Promise<void> {}
  override async length(): Promise<number> {
    return 0;
  }
  override async keys() {
    return [];
  }
  override async forEach(iteratorCallback: (value: any, key: string, iterationNumber: Number) => any): Promise<void> {}
  override setEncryptionKey(key: string) {}
}
