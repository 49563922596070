export class CommonError<CodeType extends string = string, DataType = any> extends Error {
  isCommonError: boolean = true;
  code?: CodeType;
  data?: DataType;

  constructor(transfer: CommonErrorJson<CodeType, DataType>);
  constructor(message: string, code?: CodeType, data?: DataType);
  constructor(messageOrTransfer: string | CommonErrorJson<CodeType, DataType>, code?: CodeType, data?: DataType) {
    const message = typeof messageOrTransfer === 'string' ? messageOrTransfer : messageOrTransfer.message;
    super(message);

    if (typeof messageOrTransfer === 'string') {
      this.name = 'CommonError';
      this.code = code;
      this.data = data;
    } else {
      const transfer = messageOrTransfer;
      this.name = 'CommonError';
      this.code = transfer.code;
      this.data = transfer.data;
    }
  }

  toJSON(): CommonErrorJson<CodeType, DataType> {
    return {
      isCommonError: this.isCommonError,
      name: this.name,
      code: this.code,
      message: this.message,
      data: this.data,
    };
  }
}

export interface CommonErrorJson<CodeType extends string = string, DataType = any> {
  isCommonError: boolean;
  name: string;
  message: string;
  code?: CodeType;
  data?: DataType;
}

export function isCommonError<CodeType extends string = string, DataType = any>(value: unknown): value is CommonError<CodeType, DataType> {
  return value instanceof CommonError && value.isCommonError;
}

export function isCommonErrorJson<CodeType extends string = string, DataType = any>(value: unknown): value is CommonErrorJson<CodeType, DataType> {
  return value != null && typeof value === 'object' && 'isCommonError' in value && !!(value as { isCommonError: unknown }).isCommonError;
}
