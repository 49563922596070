export const completeAll = (subjects: Array<{ complete(): void } | null | undefined>): void => {
  if (subjects == null) {
    return;
  }

  for (const sub of subjects) {
    if (sub != null) {
      sub.complete();
    }
  }
};
