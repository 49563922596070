import { InjectionToken, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { Storage, StorageConfig } from '@ionic/storage';
import { provideStorage } from './provide-storage';

const StorageConfigToken = new InjectionToken<any>('STORAGE_CONFIG_TOKEN');

@NgModule({})
export class IonicStorageModule {
  static forRoot(storageConfig?: StorageConfig): ModuleWithProviders<IonicStorageModule> {
    return {
      ngModule: IonicStorageModule,
      providers: [
        { provide: StorageConfigToken, useValue: storageConfig },
        {
          provide: Storage,
          useFactory: provideStorage,
          deps: [PLATFORM_ID, StorageConfigToken],
        },
      ],
    };
  }
}
