import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.authed) {
      const extras = this.router.getCurrentNavigation()?.extras;

      this.router.navigate(['/sign-in'], {
        queryParams: { r: state.url },
        state: { rState: this.router.getCurrentNavigation()?.extras.state },
        skipLocationChange: extras?.skipLocationChange,
        replaceUrl: extras?.replaceUrl,
      });
      return false;
    }
    return true;
  }
}
