import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { ApiService } from '../api';
import { AuthService } from '../auth';
import { BootOptions } from './boot.type';

@Injectable({
  providedIn: 'root',
})
export class BootService {
  get isBooted(): boolean {
    return this.isBootedSubject.value;
  }
  readonly isBooted$: Observable<boolean>;
  readonly bootState$: Observable<string>;

  private bootPromise?: Promise<void>;

  private readonly isBootedSubject = new BehaviorSubject<boolean>(false);
  private readonly bootStateSubject = new BehaviorSubject<string>('초기화 중...');

  constructor(
    private storage: Storage,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.isBooted$ = this.isBootedSubject.pipe(shareReplay(1));
    this.bootState$ = this.bootStateSubject.pipe(shareReplay(1));
  }

  async boot(bootOptions: BootOptions): Promise<void> {
    if (!this.bootPromise) {
      this.bootPromise = this.innerBoot(bootOptions).then(
        () => {
          this.isBootedSubject.next(true);
        },
        (err) => {
          this.isBootedSubject.next(true);
          throw err;
        }
      );
    }

    await this.bootPromise;
  }

  private async innerBoot(bootOptions: BootOptions): Promise<void> {
    this.bootStateSubject.next('앱 초기화 중...');
    await this.storage.create();

    this.bootStateSubject.next('로그인 중...');
    await this.authService.init();
    this.apiService.init(this.authService.accessToken$);

    this.bootStateSubject.next('마무리 중...');
    await new Promise((resolve) => requestAnimationFrame(resolve));
  }
}
