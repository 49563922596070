/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { isNotNullish } from './is-not-nullish';

function stringify(value: any): string {
  return (
    (value && (typeof value === 'object' || typeof value === 'function') && typeof value.toJSON === 'function'
      ? value.toJSON()
      : JSON.stringify(value)) + ''
  );
}

/**
 * 파라미터 객체를 쿼리 문자열로 변환합니다.
 *
 * @param params 파라미터 객체
 */
export function paramsToQueryString(params: { [key: string]: any }): string;
export function paramsToQueryString(params: { [key: string]: any } | null | undefined): string | null;
export function paramsToQueryString(params: { [key: string]: any } | null | undefined): string | null {
  if (params && typeof params === 'object') {
    return Object.entries(params)
      .reduce(
        (entries, [key, value]) => {
          if (Array.isArray(value)) {
            for (const valueItem of value) {
              entries.push([key, valueItem]);
            }
          } else {
            entries.push([key, value]);
          }
          return entries;
        },
        [] as Array<[string, any]>
      )
      .map(([key, value]) =>
        value !== undefined ? `${encodeURIComponent(key)}=${encodeURIComponent(typeof value === 'object' ? stringify(value) : value)}` : null
      )
      .filter(isNotNullish)
      .join('&');
  }

  return null;
}

/**
 * 쿼리 문자열을 파라미터 객체로 변환합니다.
 *
 * @param queryString 쿼리 문자열
 */
export function queryStringToParams(queryString: string): {
  [key: string]: string | Array<string>;
};
export function queryStringToParams(queryString: string | null | undefined): { [key: string]: string | Array<string> } | null;
export function queryStringToParams(queryString: string | null | undefined): { [key: string]: string | Array<string> } | null {
  if (queryString && typeof queryString === 'string') {
    const res: { [key: string]: string | Array<string> } = {};
    const split = queryString.split('&');
    for (const part of split) {
      const equalIndex = part.indexOf('=');
      const key = decodeURIComponent(part.slice(0, equalIndex));
      const value = decodeURIComponent(part.slice(equalIndex + 1));
      const oldValue = res[key];
      res[key] = oldValue == null ? value : typeof oldValue === 'string' ? [oldValue, value] : oldValue.concat(value);
    }
    return res;
  }

  return null;
}
