import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concatMap, filter, merge } from 'rxjs';
import { LifecycleComponent } from '../lifecycle-component';
import { AlertButton, AlertButtonFill, AlertCloseEventDetail, AlertInput, AlertOptions, AlertValues } from './alert.type';

const BACKDROP = 'backdrop';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent extends LifecycleComponent {
  header?: string | null;
  message?: string | null;
  inputs?: Array<AlertInput> | null;
  buttons?: Array<AlertButton>;
  buttonFill?: AlertButtonFill;
  values?: AlertValues;

  constructor(
    @Inject(MAT_DIALOG_DATA) matDialogData: AlertOptions,
    private matDialogRef: MatDialogRef<AlertComponent, AlertCloseEventDetail>
  ) {
    super();

    this.ngOnInit$.subscribe(() => {
      this.header = matDialogData.header;
      this.message = matDialogData.message;
      this.inputs = matDialogData.inputs;
      if (this.inputs) {
        this.values = this.inputs.reduce((acc, cur, idx) => {
          acc[cur.name || idx] = cur.value;
          return acc;
        }, {} as AlertValues);
      }
      this.buttons = matDialogData.buttons?.map((button: AlertButton | string) => (typeof button === 'string' ? { text: button } : button));
      this.buttonFill = matDialogData.buttonFill ?? 'clear';
    });

    this.ngOnInit$
      .pipe(
        concatMap(() => merge(this.matDialogRef.keydownEvents().pipe(filter((ev) => ev.key === 'Escape')), this.matDialogRef.backdropClick())),
        this.takeUntilDestroy()
      )
      .subscribe(() => {
        this.close(undefined, BACKDROP);
      });
  }

  onChangeInput(input: AlertInput, index: number, ev: Event): void {
    const elem = ev.target as HTMLInputElement;
    if (elem != null && this.values != null) {
      this.values[index] = elem.value;
      if (input.name) {
        this.values[input.name] = elem.value;
      }
    }
  }

  onClickButton(button: AlertButton): void {
    this.close(this.values != null ? { values: this.values } : undefined, button.role);
  }

  close(data?: AlertCloseEventDetail['data'] | undefined, role?: string): void {
    this.matDialogRef.close({ data, role });
  }
}
