import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LifecycleComponent } from '../lifecycle-component';
import { BootService } from './services/boot';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends LifecycleComponent {
  constructor(private bootService: BootService) {
    super();

    this.ngOnInit$.subscribe(() => {
      this.bootService.boot({});
    });
  }
}
