import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { LifecycleService } from '../../../lifecycle-service';
import { completeAll } from '../../utils/complete-all';
import { ApiService } from '../api';

enum StorageKey {
  token = 'celebhere-admin.auth.token',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends LifecycleService {
  get accessToken(): string | null {
    return this.accessTokenSubject.value;
  }
  get userId(): string | null {
    return this.userIdSubject.value;
  }
  get authed(): boolean {
    return this.userIdSubject.value != null;
  }

  readonly accessToken$: Observable<string | null>;
  readonly userId$: Observable<string | null>;
  readonly authed$: Observable<boolean>;

  private ngOnDestroySubject = new Subject<void>();
  private accessTokenSubject = new BehaviorSubject<string | null>(null);
  private userIdSubject = new BehaviorSubject<string | null>(null);

  constructor(private storage: Storage, private apiService: ApiService) {
    super();

    this.accessToken$ = this.accessTokenSubject.pipe(takeUntil(this.ngOnDestroySubject), shareReplay(1));
    this.userId$ = this.userIdSubject.pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroySubject), shareReplay(1));
    this.authed$ = this.userIdSubject.pipe(
      distinctUntilChanged(),
      map((userId) => userId != null),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1)
    );

    this.ngOnDestroy$.subscribe(() => {
      completeAll([this.accessTokenSubject, this.userIdSubject]);
    });
  }

  async init(): Promise<void> {
    const accessToken = (await this.storage.get(StorageKey.token)) ?? null;
    await this.setAccessToken(accessToken);
  }

  signIn(email: string, password: string): Observable<void> {
    return this.apiService.postAuthSignIn({ email, password }).pipe(switchMap((res) => this.setAccessToken(res.result.token)));
  }

  signOut(): Observable<void> {
    return this.apiService.postAuthSignOut().pipe(
      catchError((err) => {
        if (
          err instanceof Error &&
          ['세션 토큰이 유효하지 않습니다.', '세션을 찾을 수 없습니다.', '세션이 만료되었습니다.', 'invalid signature'].includes(err.message)
        ) {
          return of(undefined);
        }
        return throwError(() => err);
      }),
      switchMap(() => this.setAccessToken(null))
    );
  }

  private async setAccessToken(accessToken: string | null): Promise<void> {
    this.accessTokenSubject.next(accessToken);
    try {
      const jwtPayloadString = accessToken?.split('.')[1] ?? null;
      const jwtPayload = jwtPayloadString != null ? (JSON.parse(atob(jwtPayloadString)) as { sub?: string }) : null;
      const userId = jwtPayload?.sub ?? null;
      await this.setUserId(userId);
    } catch (err) {
      await this.setUserId(null);
      console.error(err);
    }
    if (accessToken != null) {
      await this.storage.set(StorageKey.token, accessToken);
    } else {
      await this.storage.remove(StorageKey.token);
    }
  }

  private async setUserId(userId: string | null): Promise<void> {
    this.userIdSubject.next(userId);
  }
}
