import { Injectable, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const ngOnDestroySymbol = Symbol('ngOnDestroy');

@Injectable()
export abstract class LifecycleService implements OnDestroy {
  protected readonly ngOnDestroy$: Observable<void>;

  private [ngOnDestroySymbol] = new Subject<void>();

  constructor() {
    this.ngOnDestroy$ = from(this[ngOnDestroySymbol]);
  }

  ngOnDestroy(): void {
    this[ngOnDestroySymbol].next();
    this[ngOnDestroySymbol].complete();
  }

  protected takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this[ngOnDestroySymbol]);
  }
}
