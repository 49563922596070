import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Directive,
  DoCheck,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { MonoTypeOperatorFunction, Observable, Subject, from } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { isNotNullish } from './app/utils/is-not-nullish';

const ngOnChangesSymbol = Symbol('ngOnChanges');
const ngOnInitSymbol = Symbol('ngOnInit');
const ngDoCheckSymbol = Symbol('ngDoCheck');
const ngAfterContentInitSymbol = Symbol('ngAfterContentInit');
const ngAfterContentCheckedSymbol = Symbol('ngAfterContentChecked');
const ngAfterViewInitSymbol = Symbol('ngAfterViewInit');
const ngAfterViewCheckedSymbol = Symbol('ngAfterViewChecked');
const ngOnDestroySymbol = Symbol('ngOnDestroy');

export declare class SimpleChangeGeneric<T> extends SimpleChange {
  previousValue: T;
  currentValue: T;
  firstChange: boolean;
  constructor(previousValue: T, currentValue: T, firstChange: boolean);
  /** Check whether the new value is the first value assigned. */
  isFirstChange(): boolean;
}

export type SimpleChangesGeneric<C> = {
  [P in keyof C]: SimpleChangeGeneric<C[P]>;
};

@Directive()
export abstract class LifecycleComponent
  implements OnChanges, OnInit, DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy
{
  protected readonly ngOnChanges$: Observable<SimpleChangesGeneric<this>>;
  protected readonly ngOnInit$: Observable<void>;
  protected readonly ngDoCheck$: Observable<void>;
  protected readonly ngAfterContentInit$: Observable<void>;
  protected readonly ngAfterContentChecked$: Observable<void>;
  protected readonly ngAfterViewInit$: Observable<void>;
  protected readonly ngAfterViewChecked$: Observable<void>;
  protected readonly ngOnDestroy$: Observable<void>;

  private [ngOnChangesSymbol] = new Subject<SimpleChangesGeneric<this>>();
  private [ngOnInitSymbol] = new Subject<void>();
  private [ngDoCheckSymbol] = new Subject<void>();
  private [ngAfterContentInitSymbol] = new Subject<void>();
  private [ngAfterContentCheckedSymbol] = new Subject<void>();
  private [ngAfterViewInitSymbol] = new Subject<void>();
  private [ngAfterViewCheckedSymbol] = new Subject<void>();
  private [ngOnDestroySymbol] = new Subject<void>();

  constructor() {
    this.ngOnChanges$ = from(this[ngOnChangesSymbol]);
    this.ngOnInit$ = from(this[ngOnInitSymbol]);
    this.ngDoCheck$ = from(this[ngDoCheckSymbol]);
    this.ngAfterContentInit$ = from(this[ngAfterContentInitSymbol]);
    this.ngAfterContentChecked$ = from(this[ngAfterContentCheckedSymbol]);
    this.ngAfterViewInit$ = from(this[ngAfterViewInitSymbol]);
    this.ngAfterViewChecked$ = from(this[ngAfterViewCheckedSymbol]);
    this.ngOnDestroy$ = from(this[ngOnDestroySymbol]);

    this.ngOnDestroy$.pipe(take(1)).subscribe(() => {
      this[ngOnChangesSymbol].complete();
      this[ngOnInitSymbol].complete();
      this[ngDoCheckSymbol].complete();
      this[ngAfterContentInitSymbol].complete();
      this[ngAfterContentCheckedSymbol].complete();
      this[ngAfterViewInitSymbol].complete();
      this[ngAfterViewCheckedSymbol].complete();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this[ngOnChangesSymbol].next(changes as SimpleChangesGeneric<this>);
  }
  ngOnInit(): void {
    this[ngOnInitSymbol].next();
  }
  ngDoCheck(): void {
    this[ngDoCheckSymbol].next();
  }
  ngAfterContentInit(): void {
    this[ngAfterContentInitSymbol].next();
  }
  ngAfterContentChecked(): void {
    this[ngAfterContentCheckedSymbol].next();
  }
  ngAfterViewInit(): void {
    this[ngAfterViewInitSymbol].next();
  }
  ngAfterViewChecked(): void {
    this[ngAfterViewCheckedSymbol].next();
  }
  ngOnDestroy(): void {
    this[ngOnDestroySymbol].next();
    this[ngOnDestroySymbol].complete();
  }

  protected takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this[ngOnDestroySymbol]);
  }

  protected ngOnChange$<T extends keyof this>(key: T): Observable<SimpleChangeGeneric<this[T]>> {
    return this.ngOnChanges$.pipe(
      map((changes) => changes[key]),
      filter(isNotNullish)
    );
  }
}
