import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom, Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { AlertComponent } from '.';
import { hasKeyExtend } from '../app/utils/has-key';
import { isNotNullish } from '../app/utils/is-not-nullish';
import { AlertButton, AlertCloseEventDetail, AlertInput, AlertOptions } from './alert.type';

const BACKDROP = 'backdrop';

const DEFAULT_OK_TEXT = '확인';
const DEFAULT_CANCEL_TEXT = '취소';

function openAlert(matDialog: MatDialog, config: MatDialogConfig): Observable<AlertCloseEventDetail> {
  const dialog = matDialog.open<AlertComponent, AlertOptions, AlertCloseEventDetail>(AlertComponent, config);
  return dialog.beforeClosed().pipe(filter(isNotNullish), first(undefined, { role: BACKDROP }));
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private matDialog: MatDialog) {}

  /**
   * 알림 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 방출합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   */
  alert$(header: string, message: string, okButton: string | AlertButton = DEFAULT_OK_TEXT): Observable<boolean> {
    return openAlert(this.matDialog, {
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'alert-dialog-panel',
      disableClose: true,
      data: {
        header,
        message,
        buttons: [okButton],
        cssClass: 'app-alert',
      },
    }).pipe(map((dismissDetail) => dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop'));
  }

  /**
   * 확인 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 방출합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  confirm$(
    header: string,
    message: string,
    okButton: string | AlertButton = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton = DEFAULT_CANCEL_TEXT
  ): Observable<boolean> {
    const cancelButton2: AlertButton = typeof cancelButton === 'string' ? { text: cancelButton, role: 'cancel' } : cancelButton;

    return openAlert(this.matDialog, {
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'alert-dialog-panel',
      disableClose: true,
      data: {
        header,
        message,
        buttons: [okButton, cancelButton2],
        cssClass: 'app-alert',
      },
    }).pipe(map((dismissDetail) => dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop'));
  }

  /**
   * 프롬프트 팝업을 표시합니다. 확인 팝업이 닫힐 때 입력한 데이터를 방출합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param prompt 프롬프트
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  prompt$<T = string>(
    header: string,
    message: string,
    prompt: string | AlertInput,
    okButton: string | AlertButton = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton = DEFAULT_CANCEL_TEXT
  ): Observable<T | null> {
    const prompt2: AlertInput = typeof prompt === 'string' ? { name: 'value', type: 'text', placeholder: prompt } : prompt;
    const cancelButton2: AlertButton = typeof cancelButton === 'string' ? { text: cancelButton, role: 'cancel' } : cancelButton;

    return openAlert(this.matDialog, {
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'alert-dialog-panel',
      disableClose: true,
      data: {
        header,
        message,
        inputs: [prompt2],
        buttons: [okButton, cancelButton2],
        cssClass: 'app-alert',
      },
    }).pipe(
      map((dismissDetail) =>
        dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop' ? dismissDetail.data?.values[prompt2.name || 0] ?? null : null
      )
    );
  }

  /**
   * `err` 가 `Error` 인 경우 알림 팝업을 표시합니다. 그렇지 않은 경우 `console.error(err)` 를 호출합니다.
   *
   * @param err 오류
   */
  alertError$(err: unknown): Observable<void> {
    if (err instanceof Error) {
      return this.alert$('알림', err.message).pipe(map(() => undefined));
    }

    if (
      typeof err === 'object' &&
      err != null &&
      hasKeyExtend(err, 'error') &&
      typeof err.error === 'string' &&
      hasKeyExtend(err, 'error_description') &&
      typeof err.error_description === 'string'
    ) {
      return this.alert$('알림', err.error_description).pipe(map(() => undefined));
    }

    console.error(err);
    return of(undefined);
  }

  /**
   * 알림 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   */
  async alert(header: string, message: string, okButton: string | AlertButton = DEFAULT_OK_TEXT): Promise<boolean> {
    return firstValueFrom(this.alert$(header, message, okButton));
  }

  /**
   * 확인 팝업을 표시합니다. 확인 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  async confirm(
    header: string,
    message: string,
    okButton: string | AlertButton = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton = DEFAULT_CANCEL_TEXT
  ): Promise<boolean> {
    return firstValueFrom(this.confirm$(header, message, okButton, cancelButton));
  }

  /**
   * 프롬프트 팝업을 표시합니다. 확인 팝업이 닫힐 때 입력한 데이터를 이행합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param prompt 프롬프트
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  async prompt<T = string>(
    header: string,
    message: string,
    prompt: string | AlertInput,
    okButton: string | AlertButton = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton = DEFAULT_CANCEL_TEXT
  ): Promise<T | null> {
    return firstValueFrom(this.prompt$<T>(header, message, prompt, okButton, cancelButton));
  }

  /**
   * `err` 가 `Error` 인 경우 알림 팝업을 표시합니다. 그렇지 않은 경우 `console.error(err)` 를 호출합니다.
   *
   * @param err 오류
   */
  async alertError(err: Error): Promise<void> {
    return firstValueFrom(this.alertError$(err));
  }
}
